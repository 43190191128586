:root {
	--kraken-blue-color: #00ccff;
	--kraken-white-color: #fdfaf8;
	--kraken-header-h1-color: hsl(29, 94%, 68%);
	--kraken-h1-color: #3cb371;
	--kraken-h2-color: #de6600;
	--kraken-h3-color: #007a7a;
	--kraken-text-color: #003f5a;
	/* --kraken-bg-color: #ebd9c8; */
	/* --kraken-bg-color: #fdfaf8; */
	--kraken-bg-color: #eef8f8;
	--kraken-greenbar-color: #daf9f9;
	--kraken-border-color: #007a7a;
	--kraken-normal-font: 'Happy Monkey';
    --kraken-ninja-font: 'East Sea Dokdo';
}

header {
	width: 100%;
	font-family: var(--kraken-ninja-font);
}

footer {
	width: 100%;
	font-family: var(--kraken-ninja-font);
    margin-top: 50px;
}

body {
	color: var(--kraken-text-color);
	background-color: var(--kraken-bg-color);
	font-family: var(--kraken-normal-font);
	padding: 10px;
}

body h1 {
	color: var(--kraken-h1-color);
	font-family: var(--kraken-ninja-font);
}
body h2 {
	color: var(--kraken-h2-color);
	font-family: var(--kraken-normal-font);
}
body h3 {
	color: var(--kraken-h3-color);
	font-family: var(--kraken-normal-font);
	font-size: 1.0em;
}

.kraken-feature {
	max-height: 400px;
}

.kraken-link {
	text-decoration: none;
}

footer a:hover {
	text-decoration: none;
	font-size: 1.1em !important;
	color: var(--kraken-blue-color);
}

figcaption.kraken-photo-credit {
	font-size: .7em !important;
}

a.kraken-photo-credit {
	text-decoration: none;
	color: #6c757d;
}
.kraken-photo-credit a:link, a:visited, a:active {
	text-decoration: none;
	color: #6c757d;
}
.kraken-photo-credit a:hover {
	text-decoration: none;
	color: #353636;
}

div.kraken-feature {
	
	border-style: solid;
}

div.kraken-feature h1 {
	font-size: 4em; 
}
div.kraken-feature p {
	font-size: 1.5em; 
}

img.kraken-feature {
	padding-top: 5px;
	max-height: 370px;
}

